<template>
    <div class="login-page pb-4">
        <b-container>
            <b-row>
                <b-col class="mx-auto login-card">
                    <Alert
                        v-if="alertShow"
                        v-model="alertShow"
                        :alert-text="alertText"
                        :alert-dismissible="true"
                        alert-type="danger"
                    />

                    <b-card class="p-4">
                        <div class="font-weight-bold h3 text-center mb-4">{{ $l("platon.appName", $appName) }}</div>
                        <div class="text-gray text-center my-3">{{ $l("platon.loginHeader", "Тизимга кириш") }}</div>

                        <div v-for="(provider, index) in loginProviders" :key="provider.title" class="py-2">
                            <div class="line-text" v-if="provider.divider && index > 0">
                                <span>{{ $l("platon.or", "ёки") }}</span>
                            </div>

                            <component
                                :is="provider.component"
                                @login-success="onLoginSuccess"
                                @login-error="showError"
                            />
                            <div data-v-063de322="">
                                <div data-v-063de322="">
                                    <div data-v-08b4fdc3="" class="login-page pb-4">
                                        <div data-v-08b4fdc3="" class="container">
                                            <div data-v-08b4fdc3="" class="row">
                                                <div data-v-08b4fdc3="" class="mx-auto login-card col">
                                                    <!---->
                                                    <div data-v-08b4fdc3="" class="card p-4">
                                                        <!----><!---->
                                                        <div data-v-08b4fdc3="" class="card-body">
                                                            <!----><!---->
                                                            <div
                                                                data-v-08b4fdc3=""
                                                                class="font-weight-bold h3 text-center mb-4"
                                                            >
                                                                TEST
                                                            </div>
                                                            <div data-v-08b4fdc3="" class="text-gray text-center my-3">
                                                                Тизимга кириш
                                                            </div>
                                                            <div data-v-08b4fdc3="" class="py-2">
                                                                <!---->
                                                                <form data-v-8e65c0e2="" data-v-08b4fdc3="" class="">
                                                                    <div data-v-8e65c0e2="" class="form-row">
                                                                        <fieldset
                                                                            data-v-8e65c0e2=""
                                                                            class="form-group position-relative over-hover align-self-end mb-0 col-md-12"
                                                                            autocomplete="username"
                                                                            label="Логин"
                                                                            id="__BVID__60"
                                                                        >
                                                                            <!---->
                                                                            <div>
                                                                                <div class="d-flex">
                                                                                    <label class="flex-grow-1 mb-0"
                                                                                        ><span class="mr-1">Логин</span
                                                                                        ><!----><!----></label
                                                                                    ><!---->
                                                                                </div>
                                                                                <div class="d-flex align-items-center">
                                                                                    <!----><!---->
                                                                                    <div
                                                                                        class="position-relative"
                                                                                        style="width: 100%"
                                                                                    >
                                                                                        <div
                                                                                            class="vue-simple-suggest position-relative flex-grow-1 designed"
                                                                                        >
                                                                                            <div
                                                                                                role="combobox"
                                                                                                aria-haspopup="listbox"
                                                                                                aria-owns="61-suggestions"
                                                                                                aria-expanded="false"
                                                                                                class="input-wrapper"
                                                                                            >
                                                                                                <input
                                                                                                    type="text"
                                                                                                    autocomplete="username"
                                                                                                    placeholder="Логин"
                                                                                                    inputmode=""
                                                                                                    class="form-control text-left"
                                                                                                    aria-activedescendant=""
                                                                                                    aria-autocomplete="list"
                                                                                                    aria-controls="61-suggestions"
                                                                                                />
                                                                                            </div>
                                                                                            <!---->
                                                                                        </div>
                                                                                        <!---->
                                                                                    </div>
                                                                                    <!----><!---->
                                                                                </div>
                                                                                <!----><!----><!----><!----><!----><!---->
                                                                            </div>
                                                                        </fieldset>
                                                                        <!---->
                                                                        <fieldset
                                                                            data-v-8e65c0e2=""
                                                                            class="form-group position-relative over-hover align-self-end mt-2 mb-0 col-md-12"
                                                                            type="password"
                                                                            label="Парол"
                                                                            id="__BVID__65"
                                                                        >
                                                                            <!---->
                                                                            <div>
                                                                                <div class="d-flex">
                                                                                    <label class="flex-grow-1 mb-0"
                                                                                        ><span class="mr-1">Парол</span
                                                                                        ><!----><!----></label
                                                                                    ><!---->
                                                                                </div>
                                                                                <div class="d-flex align-items-center">
                                                                                    <!----><!---->
                                                                                    <div
                                                                                        class="position-relative"
                                                                                        style="width: 100%"
                                                                                    >
                                                                                        <input
                                                                                            type="password"
                                                                                            placeholder="Парол"
                                                                                            autocomplete="current-password"
                                                                                            class="form-control form-control"
                                                                                            id="__BVID__66"
                                                                                        />
                                                                                        <div
                                                                                            class="position-absolute"
                                                                                            style="top: 7px; right: 7px"
                                                                                        >
                                                                                            <i class="fa fa-eye"></i>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </div>
                                                                                    <!----><!---->
                                                                                </div>
                                                                                <!----><!----><!----><!----><!----><!---->
                                                                            </div>
                                                                        </fieldset>
                                                                        <!----><!---->
                                                                    </div>
                                                                    <button
                                                                        data-v-8e65c0e2=""
                                                                        type="submit"
                                                                        class="btn align-items-center mt-3 btn-block btn-primary"
                                                                        variant="primary"
                                                                    >
                                                                        <!----><!---->Кириш
                                                                    </button>
                                                                </form>
                                                                <div data-v-08b4fdc3="" class="line-text">
                                                                    <span data-v-08b4fdc3="">ёки</span>
                                                                </div>
                                                            </div>
                                                            <div data-v-08b4fdc3="" class="py-2">
                                                                <!---->
                                                                <div
                                                                    data-v-08b4fdc3=""
                                                                    class="d-flex align-items-center justify-content-between gap-2"
                                                                >
                                                                    <button
                                                                        type="button"
                                                                        class="btn align-items-center rounded btn btn-warning flex-grow-1"
                                                                    >
                                                                        <!----><!----><i class="fas fa-key mr-1"></i
                                                                        ><span class="eimzo-btn-text">
                                                                            ЭРИ билан кириш
                                                                        </span></button
                                                                    ><button
                                                                        type="button"
                                                                        class="btn align-items-center rounded btn btn-warning flex-grow-1 ml-2"
                                                                    >
                                                                        <!----><!----><i class="fab fa-usb mr-1"></i
                                                                        ><span class="eimzo-btn-text">
                                                                            ID-Card билан кириш
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                                <!---->
                                                            </div>
                                                        </div>
                                                        <!----><!---->
                                                    </div>
                                                    <div
                                                        data-v-08b4fdc3=""
                                                        class="text-center d-flex justify-content-center align-items-center"
                                                    >
                                                        <div
                                                            data-v-08b4fdc3=""
                                                            class="dropdown b-dropdown m-md-2 btn-group"
                                                            id="__BVID__71"
                                                        >
                                                            <!----><button
                                                                aria-haspopup="menu"
                                                                aria-expanded="false"
                                                                type="button"
                                                                class="btn dropdown-toggle btn-link"
                                                                id="__BVID__71__BV_toggle_"
                                                            >
                                                                Ўзбекча
                                                            </button>
                                                            <ul
                                                                role="menu"
                                                                tabindex="-1"
                                                                class="dropdown-menu"
                                                                aria-labelledby="__BVID__71__BV_toggle_"
                                                            >
                                                                <li role="presentation">
                                                                    <a
                                                                        role="menuitem"
                                                                        href="#"
                                                                        target="_self"
                                                                        class="dropdown-item active"
                                                                    >
                                                                        Ўзбекча
                                                                    </a>
                                                                </li>
                                                                <li role="presentation">
                                                                    <a
                                                                        role="menuitem"
                                                                        href="#"
                                                                        target="_self"
                                                                        class="dropdown-item"
                                                                    >
                                                                        Oʻzbekcha
                                                                    </a>
                                                                </li>
                                                                <li role="presentation">
                                                                    <a
                                                                        role="menuitem"
                                                                        href="#"
                                                                        target="_self"
                                                                        class="dropdown-item"
                                                                    >
                                                                        Русский
                                                                    </a>
                                                                </li>
                                                                <li role="presentation">
                                                                    <a
                                                                        role="menuitem"
                                                                        href="#"
                                                                        target="_self"
                                                                        class="dropdown-item"
                                                                    >
                                                                        Qaraqalpaqsha
                                                                    </a>
                                                                </li>
                                                                <li role="presentation">
                                                                    <a
                                                                        role="menuitem"
                                                                        href="#"
                                                                        target="_self"
                                                                        class="dropdown-item"
                                                                    >
                                                                        Khmer
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-v-08b4fdc3="" class="text-center mt-4">
                                                <div
                                                    class="text-gray d-flex flex-row justify-content-center align-center"
                                                    style="gap: 40px"
                                                >
                                                    <div class="d-flex flex-column align-items-start">
                                                        <a
                                                            href="https://t.me/realsoft_uz"
                                                            target="_blank"
                                                            class="d-flex align-items-center"
                                                            ><i
                                                                class="fab fa-telegram-plane mr-2"
                                                                style="font-size: 20px"
                                                            ></i>
                                                            +998 (97) 136-84-84
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-v-08b4fdc3="" class="text-center mt-5">
                                                <div data-v-08b4fdc3="" class="text-center copyright">
                                                    © RealSoft 2020-2023
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="line-text" v-if="provider.divider && index + 1 < loginProviders.length">
                                <span>{{ $l("platon.or", "ёки") }}</span>
                            </div>
                        </div>
                    </b-card>

                    <div class="text-center d-flex justify-content-center align-items-center">
                        <LangPicker />
                    </div>
                </b-col>
            </b-row>

            <login-page-contacts />

            <div class="text-center mt-5">
                <div v-if="$const.COPYRIGHT_URL" class="text-center copyright">
                    <a :href="$const.COPYRIGHT_URL" target="_blank" v-html="`© ${$copyright}`"></a>
                </div>
                <div v-else class="text-center copyright" v-html="`© ${$copyright}`"></div>
            </div>
        </b-container>
    </div>
</template>

<script>
import { mapMutations } from "vuex"
import { ENABLED_LOGIN_PROVIDERS } from "@Platon/const"
import LoginMixin from "@Platon/components/login/LoginMixin"
import Alert from "@Platon/components/extended/Alert.vue"
import LangPicker from "@Platon/components/misc/LangPicker.vue"
import { providers } from "./Providers"
import DialogWithOTPVerification from "@Platon/components/login/DialogWithOTPVerification.vue"

export default {
    name: "Login",
    components: { LangPicker, Alert, DialogWithOTPVerification },

    mixins: [LoginMixin],

    data() {
        return {
            alertText: "",
            alertShow: false
        }
    },

    created() {
        localStorage.removeItem("login_eimzo_key")
        localStorage.removeItem("user_organizations")
    },

    methods: {
        ...mapMutations({
            doLogin: "platon/doLogin"
        }),

        showError(text) {
            this.alertText = text
            this.alertShow = !!text
        },

        getProvider(provider) {
            return providers[provider] ?? null
        }
    },

    computed: {
        loginProviders() {
            return ENABLED_LOGIN_PROVIDERS.map((provider) => this.getProvider(provider))
        },

        hasMultipleProviders() {
            return this.loginProviders.length > 1
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/vars";

.login-page {
    background: var(--pl-login-page-bg-color);
    min-height: 100vh;
}

.login-card {
    width: 500px;
    max-width: 500px;
    margin-top: 12vh;
}

.text-gray {
    color: #9a9a9a;
}

.copyright {
    color: darken($gray-2, 30%);
}

.line-text {
    padding: 12px 0;
    position: relative;
    text-align: center;
}

.line-text:before {
    content: "";
    display: block;
    border-top: solid 1px #dcdcdc;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    z-index: 0;
}

.line-text span {
    color: var(--pl-login-page-line-text-color);
    background: var(--pl-card-bg);
    padding: 0 16px;
    position: relative;
    z-index: 1;
}
</style>
